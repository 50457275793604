import {
  styled,
  spacing,
  Text,
  mq,
  css,
  margin,
  padding,
} from '@thg-commerce/gravity-theme'
import {
  Button,
  CheckboxContainer,
  CheckboxLabel,
  StyledCheckbox,
  StyledTick,
  CheckboxText,
  StyledErrorMessage,
} from '@thg-commerce/gravity-elements'
import { FormItem } from '@thg-commerce/gravity-patterns/Form'
import { Margin } from '@thg-commerce/gravity-theme/margin'

export const OutOfStockWrapper = styled.div<{
  margin: Margin
}>`
  ${(props) => margin(props.margin)}
`

export const OutOfStockContent = styled.div<{
  padding?: string
  backgroundColor?: string
  checkboxContainerPadding?: {
    top: string
    right: string
    bottom: string
    left: string
  }
  checkboxLabelPadding?: string
}>`
  border-radius: ${(props) =>
    props.theme.formgroup.general.border.default.radius};
  ${(props) => props.padding && `padding: ${props.padding} ;`}
  ${(props) =>
    props.backgroundColor && `background-color: ${props.backgroundColor};`}

  ${(props) =>
    props.checkboxLabelPadding &&
    css`
      ${CheckboxLabel} {
        padding: ${props.checkboxLabelPadding};
      }
    `}

  ${CheckboxContainer} {
    height: auto;
    ${(props) =>
      props.checkboxContainerPadding && padding(props.checkboxContainerPadding)}
  }

  ${StyledCheckbox} {
    display: block;
    margin-top: ${spacing(0.5)};
  }

  ${StyledTick} {
    height: 24px;
    padding: 3px;
    width: 24px;
  }

  ${CheckboxText} {
    ${Text('bodyText', 'default')};
  }

  ${StyledErrorMessage} {
    margin-left: 0;
  }
`

export const Heading = styled.h2<{
  marginBottom?: string
}>`
  ${Text('medium1', 'alternate')}
  margin-bottom: ${(props) => props.marginBottom};
`

export const Paragraph = styled.p`
  margin-bottom: ${spacing(2)};
`

export const FooterMessaging = styled.p`
  margin: ${spacing(2)} 0 0;
`

export const StyledButton = styled(Button)<{ mobileWidth?: string }>`
  margin-top: ${spacing(2)};
  ${(props) => props.mobileWidth && `width: ${props.mobileWidth};`}

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    width: auto;
  }
`

export const StyledFormItem = styled(FormItem)<{
  marginTop?: string
}>`
  ${(props) =>
    props.marginTop &&
    css`
      margin-top: ${props.marginTop};
    `};
`
