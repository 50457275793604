import * as React from 'react'

import { FormItem as BaseFormItem } from '@thg-commerce/gravity-elements'
import {
  FormItemInputProps,
  FormItemProps,
} from '@thg-commerce/gravity-elements/FormItem/FormItem'

import { InView } from 'react-intersection-observer'

// Fix: IntersectionObserver doesn't exist warning on window
declare global {
  interface Window {
    IntersectionObserver: typeof IntersectionObserver
  }
}
// End Fix

export const FormItem = (props: FormItemInputProps & FormItemProps) => {
  if (typeof Window !== 'undefined') {
    return (
      <InView rootMargin={`-${props.offset}px`}>
        {({ inView, ref }) => (
          <div ref={ref}>
            <BaseFormItem {...props} inView={inView} />
          </div>
        )}
      </InView>
    )
  }

  return (
    <div>
      <BaseFormItem {...props} />
    </div>
  )
}

export default FormItem
