import * as React from 'react'

import { TextStyling } from '@thg-commerce/gravity-patterns'
import {
  BreakpointArray,
  css,
  Direction,
  mediaQueryRenderer,
  spacing,
  styled,
  Text,
} from '@thg-commerce/gravity-theme'

import { PriceTheme } from '../types'

const ProductPriceContainer = styled.div<{
  priceDirection: BreakpointArray<Direction>
  isSticky?: boolean
}>`
  display: flex;
  ${(props) =>
    mediaQueryRenderer(props.priceDirection, (direction) => {
      return props.isSticky
        ? `
      flex-direction: column;
      align-items: flex-start;
    `
        : `
        gap: ${spacing(1)};
        flex-direction: ${direction};
        align-items: ${direction === Direction.ROW ? 'center' : 'flex-start'};
      `
    })}
`

const PriceContainer = styled.div<{
  priceDirection: BreakpointArray<Direction>
}>`
  display: flex;
  gap: ${spacing(1)};
  ${(props) =>
    mediaQueryRenderer(props.priceDirection, (direction) => {
      return `
        flex-direction: ${direction};
        align-items: ${direction === Direction.ROW ? 'center' : 'flex-start'};
      `
    })}
`

const SavingsContainer = styled.div<{
  priceDirection: BreakpointArray<Direction>
}>`
  display: flex;
  gap: ${spacing(1)};
  ${(props) =>
    mediaQueryRenderer(props.priceDirection, (direction) => {
      return `
        flex-direction: ${direction};
        align-items: ${direction === Direction.ROW ? 'center' : 'flex-start'};
      `
    })}
`

const DiscountMessage = styled.div<{
  discountMessageStyle?: TextStyling
}>`
  margin: ${spacing(2)} 0;
  ${(props) =>
    props.discountMessageStyle
      ? css`
          ${Text(
            props.discountMessageStyle.entry,
            props.discountMessageStyle.style,
          )};
          color: ${props.discountMessageStyle.textColor};
          text-decoration: ${props.discountMessageStyle.textDecoration};
        `
      : css`
          ${Text('bodyText', 'default')};
          color: ${({ theme }) => theme.colors.error.base};
        `}
`

const RrpContainer = styled.div<{
  priceDirection: BreakpointArray<Direction>
  rrpStyle?: TextStyling
  background?: String
}>`
  ${(props) =>
    mediaQueryRenderer(props.priceDirection, (direction) => {
      return direction === Direction.ROW && 'order: 2;'
    })}
  ${(props) =>
    props.rrpStyle
      ? css`
          ${Text(props.rrpStyle.entry, props.rrpStyle.style)};
          color: ${props.rrpStyle.textColor};
        `
      : css`
          ${Text('bodyText', 'default')};
          color: ${({ theme }) => theme.colors.palette.greys.dark};
        `}
  ${(props) =>
    props.background &&
    `
      background-color: ${props.background}; 
      padding: ${spacing(0.25)} ${spacing(1)}
    `}
`

const RrpText = styled.span<{
  rrpStyle?: TextStyling
}>`
  ${(props) =>
    props.rrpStyle &&
    css`
      text-decoration: ${props.rrpStyle.textDecoration};
    `}
`

const SaveText = styled.span<{
  priceDirection: BreakpointArray<Direction>
  savingStyle?: TextStyling
  background?: String
}>`
  ${(props) =>
    mediaQueryRenderer(props.priceDirection, (direction) => {
      return direction === Direction.ROW && 'order: 3;'
    })}
  ${(props) =>
    props.savingStyle
      ? css`
          ${Text(props.savingStyle.entry, props.savingStyle.style)};
          color: ${props.savingStyle.textColor};
          text-decoration: ${props.savingStyle.textDecoration};
        `
      : css`
          ${Text('bodyText', 'default')};
          color: ${({ theme }) => theme.colors.error.base};
        `}
  ${(props) =>
    props.background &&
    `
      background-color: ${props.background};
      padding: ${spacing(0.25)} ${spacing(1)}
    `}
`

const PriceText = styled.span<{
  priceDirection: BreakpointArray<Direction>
  textStyle?: TextStyling
}>`
  ${(props) =>
    mediaQueryRenderer(props.priceDirection, (direction) => {
      return direction === Direction.ROW && 'order: 1; flex-shrink: 0;'
    })}

  ${(props) => css`
    ${Text(
      props.textStyle?.entry || 'large2',
      props.textStyle?.style || 'alternate',
    )};
    text-decoration: ${props.textStyle?.textDecoration || 'none'};
    ${props.textStyle?.textColor ? `color: ${props.textStyle?.textColor};` : ''}
  `}
  ${(props) =>
    props.background &&
    `
      background-color: ${props.background};
      padding: ${spacing(0.25)} ${spacing(1)}
  `}
`

export interface PriceProps {
  pricing: {
    price: string
    rrp: string
  }
  // @TODO REBUILD-XXX: Add savings to all uses of price
  savings?: {
    show: boolean
    textStyle?: TextStyling
    background?: boolean
  }
  savingsPercentage?: {
    show: boolean
  }
  i18nText?: {
    rrpLabel?: string
    saveLabel?: string
    fromLabel?: string
    offLabel?: string
  }
  displayRRPText?: boolean
  hasFromPrice?: boolean
  priceDirection?: BreakpointArray<Direction>
  priceTheme?: PriceTheme
  isSticky?: boolean
  discountMessageProps?: {
    text: string | null
    textStyle?: TextStyling
  }
}

const renderPrice = (savingsPercentage, savePercentage, offLabel) => {
  return (
    savingsPercentage?.show &&
    savePercentage &&
    ` (${savePercentage}% ${offLabel || ''})`
  )
}

const renderSavingsPrice = (
  savings,
  save,
  hasFromPrice,
  priceDirection,
  priceTheme,
  i18nText,
  savingsPercentage,
  savePercentage,
) => {
  const displaySaveText = (!savings || savings.show) && save && !hasFromPrice
  return (
    <React.Fragment>
      {displaySaveText && (
        <SaveText
          data-testid="product-price-save"
          priceDirection={priceDirection || [Direction.ROW]}
          savingStyle={priceTheme?.savings?.textStyle || savings?.textStyle}
          background={priceTheme?.savings?.background}
        >
          {i18nText?.saveLabel && `${i18nText.saveLabel}: `}
          {save}
          {renderPrice(savingsPercentage, savePercentage, i18nText.offLabel)}
        </SaveText>
      )}
    </React.Fragment>
  )
}

const renderRRP = (
  save,
  hasFromPrice,
  pricing,
  priceDirection,
  priceTheme,
  i18nText,
  displayRRPText,
) => {
  return (
    <React.Fragment>
      {save && !hasFromPrice && pricing.rrp && (
        <RrpContainer
          data-testid="product-rrp-container"
          priceDirection={priceDirection || [Direction.ROW]}
          rrpStyle={priceTheme?.rrp.textStyle}
          background={priceTheme?.rrp.background}
        >
          {displayRRPText && i18nText?.rrpLabel && `${i18nText.rrpLabel}: `}
          <RrpText
            data-testid="product-price-rrp"
            rrpStyle={priceTheme?.rrp.textStyle}
          >
            {pricing.rrp}
          </RrpText>
        </RrpContainer>
      )}
    </React.Fragment>
  )
}
export const Price = (props: PriceProps) => {
  const save = React.useMemo(() => {
    const currencySymbol = props.pricing.price
      .replace(/[0-9\.,]+/g, '')
      .charAt(0)
    const indexOfCurrency = props.pricing.price.indexOf(currencySymbol)
    const priceAsNumber = parseFloat(
      props.pricing.price.replace(/[^0-9\.,]+/g, ''),
    )
    const rrpAsNumber = props.pricing?.rrp
      ? parseFloat(props.pricing?.rrp?.replace(/[^0-9\.,]+/g, ''))
      : null

    if (
      priceAsNumber === rrpAsNumber ||
      priceAsNumber > rrpAsNumber ||
      isNaN(priceAsNumber) ||
      isNaN(rrpAsNumber)
    ) {
      return null
    }
    const savings = (rrpAsNumber - priceAsNumber).toFixed(2)
    return indexOfCurrency !== 0
      ? `${savings}${currencySymbol}`
      : `${currencySymbol}${savings}`
  }, [props.pricing])

  const savePercentage = React.useMemo(() => {
    const priceAsNumber = parseFloat(
      props.pricing.price.replace(/[^0-9\.,]+/g, ''),
    )
    const rrpAsNumber = parseFloat(props.pricing.rrp.replace(/[^0-9\.,]+/g, ''))

    if (
      priceAsNumber === rrpAsNumber ||
      priceAsNumber > rrpAsNumber ||
      isNaN(priceAsNumber) ||
      isNaN(rrpAsNumber)
    ) {
      return null
    }

    const savingsPercentage = (
      ((rrpAsNumber - priceAsNumber) / rrpAsNumber) *
      100
    ).toFixed()

    return savingsPercentage
  }, [props.pricing])

  return (
    <ProductPriceContainer
      data-testid="product-price-container"
      priceDirection={props.priceDirection || [Direction.ROW]}
      isSticky={props.isSticky}
    >
      <PriceContainer priceDirection={props.priceDirection || [Direction.ROW]}>
        {renderRRP(
          save,
          props.hasFromPrice,
          props.pricing,
          props.priceDirection,
          props.priceTheme,
          props.i18nText,
          props.displayRRPText,
        )}
        <PriceText
          data-testid="product-price-price"
          priceDirection={props.priceDirection || [Direction.ROW]}
          textStyle={
            save && !props.hasFromPrice
              ? props.priceTheme?.priceWithRRP.textStyle
              : props.priceTheme?.price.textStyle
          }
        >
          {props.hasFromPrice &&
            props.i18nText?.fromLabel &&
            `${props.i18nText.fromLabel}: `}
          {props.pricing.price}
        </PriceText>
      </PriceContainer>
      <SavingsContainer
        priceDirection={props.priceDirection || [Direction.ROW]}
      >
        {renderSavingsPrice(
          props.savings,
          save,
          props.hasFromPrice,
          props.priceDirection,
          props.priceTheme,
          props.i18nText,
          props.savingsPercentage,
          savePercentage,
        )}
        {props.discountMessageProps?.text && (
          <DiscountMessage
            discountMessageStyle={props.discountMessageProps?.textStyle}
          >
            {props.discountMessageProps.text}
          </DiscountMessage>
        )}
      </SavingsContainer>
    </ProductPriceContainer>
  )
}
