import * as React from 'react'
import { useMutation } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { vsprintf } from 'sprintf-js'

import {
  i18n,
  RateLimitBucket,
  useCaptcha,
  useLogger,
  useRequestConfig,
} from '@thg-commerce/enterprise-core'
import {
  AddToWaitlistResponseStatus,
  MarketingConsentAuditData,
  WaitlistType,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import { useTheme } from '@thg-commerce/enterprise-theme'
import {
  Checkbox,
  Input,
  PlatformMessage,
  Separator,
} from '@thg-commerce/gravity-elements'
import { DynamicForm } from '@thg-commerce/gravity-patterns'
import { Margin } from '@thg-commerce/gravity-theme/margin'

import {
  FooterMessaging,
  Heading,
  OutOfStockContent,
  OutOfStockWrapper,
  Paragraph,
  StyledButton,
  StyledFormItem,
} from './styles'

enum AlternateOutOfStockNotification {
  ALTERNATE = 'alternate',
}

export const ADD_PRODUCT_TO_WAITLIST_BY_TYPE_MUTATION = gql`
  mutation AddProductToWaitlistByType($input: AddProductWaitlistInput!) {
    addProductToWaitlistByType(input: $input) {
      status
    }
  }
`

interface FieldValue {
  [key: string]: string | MarketingConsentAuditData
}

export type OutOfStockNotificationProps = {
  email?: string
  sku: number
  url: string
  inViewport: boolean
  enterCount: number
  forwardedRef: React.Ref<any>
  defaultOptInChecked: boolean
  styleOverride?: {
    margin: Margin
  }
}

export enum ADD_TO_WAITLIST_RESPONSES {
  pass = 'PASS',
  failed = 'FAILED',
  requiresVerification = 'REQUIRES_VERIFICATION',
}

export const OutOfStockNotification = (props: OutOfStockNotificationProps) => {
  const logger = useLogger()
  const { csrf } = useRequestConfig()
  const theme = useTheme()

  const [submitStatus, setSubmitStatus] = React.useState<string>('')
  const [isCheckboxTicked, setIsCheckboxTicked] = React.useState<boolean>(
    props.defaultOptInChecked,
  )
  const [hasCheckboxBeenChanged, setHasCheckboxBeenChanged] = React.useState<
    boolean
  >(false)

  const captcha = useCaptcha({ bucket: RateLimitBucket.WAITLIST })

  const i18nText = {
    gdprDisclaimer: i18n('product.outofstocknotification.gdprdisclaimer'),
    successLegendText: i18n(
      'product.outofstocknotification.success.legend.text',
    ),
    alertEmailRequired: i18n(
      'product.outofstocknotification.alert.email.required',
    ),
    alertCheckboxRequired: i18n(
      'product.outofstocknotification.alert.checkbox.required',
    ),
    mainText: i18n('product.outofstocknotification.main.text'),
    alertEmailInvalid: i18n(
      'product.outofstocknotification.alert.email.invalid',
    ),
    resultFailure: i18n('product.outofstocknotification.result.failure'),
    legendText: i18n('product.outofstocknotification.legend.text'),
    submit: i18n('product.outofstocknotification.submit'),
    successMainText: i18n('product.outofstocknotification.success.main.text'),
    recaptchaRequired: i18n(
      'product.outofstocknotification.recaptcha.required',
    ),
    email: i18n('product.outofstocknotification.email'),
    privacyPolicyLinkText: i18n(
      'product.outofstocknotification.privacypolicylink.text',
    ),
    privacyPolicyLinkUrl: i18n(
      'product.outofstocknotification.privacypolicylink.url',
    ),
    footerMessaging: i18n('product.outofstocknotification.footer.text'),
  }
  const marketingLabel = vsprintf('%s<a href="%s" target="_blank">%s</a>', [
    i18nText.gdprDisclaimer,
    i18nText.privacyPolicyLinkUrl,
    i18nText.privacyPolicyLinkText,
  ])

  const [addProductToWaitlistByType] = useMutation(
    ADD_PRODUCT_TO_WAITLIST_BY_TYPE_MUTATION,
    {
      onCompleted: (data) => {
        setSubmitStatus(data.addProductToWaitlistByType.status)
      },
      onError: (error) => {
        logger.warn(
          `[OutOfStockNotification]: Failed to add product to waitlist with error ${error.message}`,
        )
        setSubmitStatus(AddToWaitlistResponseStatus.Failed)
      },
    },
  )

  const attemptAddProductToWaitlistByType = (
    formFields: FieldValue,
    captchaResponse?: string,
  ) => {
    addProductToWaitlistByType({
      variables: {
        input: {
          sku: props.sku,
          waitlistType: WaitlistType.Email,
          emailDetails: {
            email: formFields['emailAddress'],
            marketingConsentAuditData: {
              messageShown: i18nText.gdprDisclaimer,
              formIdentifier: 'WAITLIST',
              formLocation: props.url,
            },
          },
        },
      },
      context: {
        headers: {
          'X-Captcha-Type': captcha.type,
          'X-Captcha-Response': captchaResponse,
        },
      },
    })
  }

  const onSubmitHandler = (fields: FieldValue) => {
    if (!isCheckboxTicked) {
      !hasCheckboxBeenChanged && setHasCheckboxBeenChanged(true)
      return
    }

    captcha.submit((response) =>
      attemptAddProductToWaitlistByType(fields, response),
    )
  }

  const formFields = [
    {
      fieldName: 'marketingPreference',
      renderField: () => (
        <React.Fragment>
          <Checkbox
            checked={props.defaultOptInChecked}
            error={
              hasCheckboxBeenChanged && !isCheckboxTicked
                ? i18nText.alertCheckboxRequired
                : undefined
            }
            label={marketingLabel}
            transparentBorder={
              theme.widget.productList?.outOfStockNotification?.checkbox
                ?.transparentBorder
            }
            required
            onChange={(value) => {
              setIsCheckboxTicked(value)
              !hasCheckboxBeenChanged && setHasCheckboxBeenChanged(true)
            }}
          />
        </React.Fragment>
      ),
    },
    {
      fieldName: 'emailAddress',
      renderField: (ref, key) => (
        <StyledFormItem
          bindref={ref}
          key={key}
          offset={1}
          disableSpacing
          marginTop={
            props.defaultOptInChecked
              ? '0'
              : theme.widget?.productList.outOfStockNotification?.emailField
                  ?.margin?.top
          }
        >
          <Input
            i18nText={{
              requiredError: i18nText.alertEmailRequired,
              emailValidationError: i18nText.alertEmailInvalid,
            }}
            label={i18nText.email}
            type="email"
            required
            valueOverride={props.email}
          />
        </StyledFormItem>
      ),
    },
  ]

  const outOfStockMargin = props.styleOverride?.margin ||
    theme.widget?.productList.outOfStockNotification?.container?.margin || {
      top: '0',
      right: '0',
      bottom: '0',
      left: '0',
    }

  return (props.enterCount && props.enterCount > 0) || props.inViewport ? (
    <div
      ref={props.forwardedRef}
      style={{ width: '100%' }}
      data-testid="out-of-stock-notification"
    >
      <OutOfStockWrapper margin={outOfStockMargin}>
        {submitStatus !== ADD_TO_WAITLIST_RESPONSES.pass && (
          <OutOfStockContent
            padding={
              theme.widget?.productList.outOfStockNotification?.contentWrapper
                ?.padding
            }
            backgroundColor={
              theme.widget?.productList?.outOfStockNotification?.contentWrapper
                ?.backgroundColor
            }
            checkboxContainerPadding={
              theme.widget?.productList?.outOfStockNotification?.checkbox
                ?.container?.padding
            }
            checkboxLabelPadding={
              theme.widget?.productList?.outOfStockNotification?.checkbox?.label
                ?.padding
            }
          >
            <Heading
              marginBottom={
                theme.widget?.productList?.outOfStockNotification?.heading
                  ?.margin?.bottom
              }
            >
              {i18nText.mainText}
            </Heading>
            {theme.widget?.productList?.outOfStockNotification?.subtitle
              ?.show && <Paragraph>{i18nText.legendText}</Paragraph>}
            {submitStatus === ADD_TO_WAITLIST_RESPONSES.failed && (
              <PlatformMessage
                type="error"
                text={i18nText.resultFailure}
                data-testid="add-product-to-waitlist-failure"
              />
            )}
            <DynamicForm
              csrf={csrf}
              data-testid="dynamic-form-add-product-to-wishlist"
              fields={props.defaultOptInChecked ? [formFields[1]] : formFields}
              onSubmit={onSubmitHandler}
            >
              {captcha.captcha}
              <StyledButton
                type="submit"
                data-testid="button-submit-add-product-to-waitlist"
                mobileWidth={
                  theme.widget?.productList?.outOfStockNotification
                    ?.submitButton?.sm?.width
                }
                onClick={() => {
                  if (!isCheckboxTicked) {
                    !hasCheckboxBeenChanged && setHasCheckboxBeenChanged(true)
                  }
                }}
              >
                {i18nText.submit}
              </StyledButton>
            </DynamicForm>
            {theme.widget?.productList?.outOfStockNotification?.footerMessaging
              ?.show && (
              <FooterMessaging>{i18nText.footerMessaging}</FooterMessaging>
            )}
          </OutOfStockContent>
        )}
        {submitStatus === ADD_TO_WAITLIST_RESPONSES.pass &&
          (theme.widget?.productList?.outOfStockNotification?.successMessage
            ?.variant === AlternateOutOfStockNotification.ALTERNATE ? (
            <PlatformMessage
              type="success"
              text={i18nText.successLegendText}
              data-testid="add-product-to-waitlist-success"
            />
          ) : (
            <React.Fragment>
              <Heading
                marginBottom={
                  theme.widget?.productList?.outOfStockNotification?.heading
                    ?.margin?.bottom
                }
                data-testid="add-product-to-waitlist-thank-you-heading"
              >
                {i18nText.successMainText}
              </Heading>
              <Paragraph>{i18nText.successLegendText}</Paragraph>
            </React.Fragment>
          ))}
      </OutOfStockWrapper>
      {theme.widget?.productList?.outOfStockNotification?.separator?.show && (
        <Separator withSpacing />
      )}
    </div>
  ) : (
    <div style={{ visibility: 'hidden' }} ref={props.forwardedRef} />
  )
}
