import * as React from 'react'
import handleViewport from 'react-in-viewport'

import {
  useCustomer,
  useRequestConfig,
  useSiteConfig,
  useSiteDefinition,
} from '@thg-commerce/enterprise-core'
import { Margin } from '@thg-commerce/gravity-theme/margin'

import { VariantFields } from '../src/transformer/productOptionsTransformer'
import { OutOfStockNotification } from '../OutOfStockNotification'

interface QuickBuyStockNotification {
  mainProduct?: boolean
  selectedVariant?: VariantFields
  url?: string
  styleOverride?: {
    margin: Margin
  }
}

export const QuickBuyStockNotification = (props: QuickBuyStockNotification) => {
  const {
    showOutOfStockNotification,
    enablePushNotifyWhenInStock,
  } = useSiteConfig()
  const { domain } = useSiteDefinition()
  const { isMobileApp } = useRequestConfig()
  const {
    email,
    emailMarketingPreference,
    smsMarketingPreference,
  } = useCustomer()

  const hasPushNotificationEnabled =
    enablePushNotifyWhenInStock &&
    isMobileApp &&
    props.selectedVariant?.notifyWhenInStockEnabled &&
    !props.selectedVariant.inStock

  if (
    !showOutOfStockNotification ||
    !(
      props.selectedVariant &&
      !props.selectedVariant?.inStock &&
      props.selectedVariant?.notifyWhenInStockEnabled
    ) ||
    hasPushNotificationEnabled
  ) {
    return null
  }

  const OutOfStockNotificationViewport = handleViewport(OutOfStockNotification)

  return (
    <OutOfStockNotificationViewport
      email={email}
      sku={props.selectedVariant.sku}
      url={`https://${domain}${props.url}`}
      defaultOptInChecked={
        emailMarketingPreference || smsMarketingPreference || false
      }
      styleOverride={props.styleOverride}
    />
  )
}
